import { useToast } from "@chakra-ui/react";
import captureException from "app/utils/captureException";
import { useQueryParam } from "app/utils/history";
import React from "react";
import { useHistory } from "react-router-dom";

const errorToastDuration = 8000;

const useSocialSigninErrorHandler = () => {
    const params = useQueryParam();
    const erroToast = useToast({ duration: errorToastDuration, isClosable: true });
    const history = useHistory();
    const error = params.get("error");

    React.useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (error) {
            try {
                const parsedError = JSON.parse(error);
                if (!erroToast.isActive("social-signin-error")) {
                    erroToast({
                        id: "social-signin-error",
                        status: "error",
                        ...parsedError,
                    });
                }
                timeout = setTimeout(() => {
                    history.push({
                        pathname: history.location.pathname,
                    });
                }, errorToastDuration);
            } catch (e) {
                captureException(e);
                erroToast({
                    id: "social-signin-error",
                    status: "error",
                    title: "Something went wrong!",
                });
            }
        }

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return null;
};

export default useSocialSigninErrorHandler;
