import { Badge, Box, Button, Grid, HStack, Text, VStack } from "@chakra-ui/react";
import EmptyState from "app/components/EmptyState";
import GBTooltip from "app/components/GBTooltip";
import LoadingState from "app/components/LoadingState";
import Table from "app/components/Tables/SimpleTableV2";
import { useGetIntegrationLogs } from "app/fetchHooks/integration";
import {
    CashFreeMeta,
    FBLeadsMeta,
    GenericWebHookMeta,
    IntegrationLog,
    LeadSquaredMeta,
    RazorpayMeta,
    ShipRocketMeta,
    ShipwayMeta,
    ShopifyMeta,
    WCCartFlowMeta,
    WCAbandonedCartMeta,
    WooCommerceMeta,
    ZohoBooksMeta,
    CleverTapMeta,
    StripeMetaData,
    HubspotMeta,
} from "app/types/integration/integrationLog";
import { formatDate2 } from "app/utils/date";
import { useAccount, useIntersectionObserver } from "app/utils/react-helpers";
import dayjs from "dayjs";
import React, { useRef } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { CellProps, Column } from "react-table";
import PIIValidatedText from "app/gbComponents/Text/PIIValidatedText";
import { stripeWorkFlowEvents } from "./StripeIntegration/StripeWorkFlow";
import { ShopfloMeta } from "app/types/integration/shopFlo";

const getWorkFlowName = (integrationType: IntegrationLog["integrationType"], value: IntegrationLog["meta"]) => {
    let result;
    if (integrationType === "cashfree") {
        result = (value as CashFreeMeta)?.eventName;
    } else if (integrationType === "shiprocket") {
        result = (value as ShipRocketMeta)?.statusName;
    } else if (integrationType === "shipway") {
        result = (value as ShipwayMeta)?.eventName;
    } else if (integrationType === "zohobooks") {
        result = (value as ZohoBooksMeta)?.workFlowName;
    } else if (integrationType === "shopflo") {
        result = (value as ShopfloMeta)?.name;
    } else {
        result = (value as ShopifyMeta)?.topicTitle;
    }

    return result;
};

const getColumns = (integrationType?: IntegrationLog["integrationType"]): Column<IntegrationLog>[] => {
    const status: Column<IntegrationLog> = {
        Header: "Status",
        accessor: "status",
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => {
            const rowData = row.original;
            const status = rowData.status;
            const errorMessage = rowData.errorMessage;
            const ignoreMessage = rowData?.remark;

            if (status === "completed") {
                return <Badge color="green.500">{status}</Badge>;
            }
            if (status === "error") {
                return (
                    <HStack>
                        <Badge color="red.500">{status}</Badge>
                        <GBTooltip label={errorMessage}>
                            <span>
                                <HiOutlineInformationCircle />
                            </span>
                        </GBTooltip>
                    </HStack>
                );
            }
            if (status === "started" || status === "scheduled") {
                return <Badge color="blue.300">{status}</Badge>;
            }
            if (status === "ignored") {
                return (
                    <HStack>
                        <Badge color="gray.500">{status}</Badge>
                        <GBTooltip label={ignoreMessage}>
                            <span>
                                <HiOutlineInformationCircle />
                            </span>
                        </GBTooltip>
                    </HStack>
                );
            }
            return null;
        },
        id: "status",
    };
    const fullName: Column<IntegrationLog> = {
        Header: "Customer Name",
        accessor: "meta",
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => {
            return (
                <Text maxW="16ch" isTruncated>
                    {(value as ShopifyMeta | WooCommerceMeta | FBLeadsMeta)?.fullName || (value as CashFreeMeta)?.name}
                </Text>
            );
        },
        id: "CustomerName",
    };
    const phone: Column<IntegrationLog> = {
        Header: "Mobile Number",
        accessor: "meta",
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => {
            const phone = value?.phone;
            return <PIIValidatedText value={phone ?? "N/A"} maxW="16ch" isTruncated />;
        },
        id: "MobileNumber",
    };
    const paymentMethod: Column<IntegrationLog> = {
        Header: "Payment Method",
        accessor: "meta",
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) => {
            return (
                <Text maxW="16ch" isTruncated>
                    {(value as WooCommerceMeta)?.paymentMethod}
                </Text>
            );
        },
        id: "paymentMethod",
    };

    if (integrationType === "razorpay") {
        return [
            {
                Header: "event name",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <Text>{(value as RazorpayMeta)?.eventName}</Text>;
                },
                id: "eventName",
            },

            {
                Header: "reference id",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <Text>{(value as RazorpayMeta)?.referenceId}</Text>;
                },
                id: "referenceId",
            },

            {
                Header: "mobile",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <PIIValidatedText value={(value as RazorpayMeta)?.phone ?? "N/A"} />;
                },
                id: "mobile",
            },

            {
                Header: "created at",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const createdAt = (value as RazorpayMeta)?.orderCreatedAt as string;
                    return <Text>{dayjs(createdAt).format("DD MMM ,YYYY") ?? "N/A"}</Text>;
                },
                id: "createdAt",
            },
            {
                Header: "amount",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const currency = (value as RazorpayMeta)?.currency;
                    return <Text>{`${currency} ${(value as RazorpayMeta)?.amount}`}</Text>;
                },
                id: "amount",
            },
            status,
        ];
    }
    if (integrationType === "stripe") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    const dateLabel = dayjs(value).format("ddd, MMM D, YYYY h:mm A");
                    return <GBTooltip label={dateLabel}>{date}</GBTooltip>;
                },
                id: "createdAt",
            },
            {
                Header: "event name",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <Text>{stripeWorkFlowEvents[(value as StripeMetaData)?.eventType]}</Text>;
                },
                id: "eventName",
            },
            {
                Header: "mobile",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <PIIValidatedText value={(value as StripeMetaData)?.phone ?? "N/A"} />;
                },
                id: "mobile",
            },
            {
                Header: "amount",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <Text>{`${(value as StripeMetaData)?.amount}`}</Text>;
                },
                id: "amount",
            },
            status,
        ];
    }
    if (integrationType === "fbleads") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    return <Text>{date}</Text>;
                },
                id: "startedat",
            },

            {
                Header: "Page",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <Text>{(value as FBLeadsMeta)?.page ?? "N/A"}</Text>;
                },
                id: "Page",
            },
            {
                Header: "Form",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <Text>{(value as FBLeadsMeta)?.form ?? "N/A"} </Text>;
                },
                id: "Form",
            },
            fullName,
            phone,
            status,
        ];
    }

    if (integrationType === "genericWebhook") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    return <Text>{date}</Text>;
                },
                id: "startedat",
            },
            {
                Header: "WorkFlow Name",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <Text>{(value as GenericWebHookMeta)?.eventName}</Text>;
                },
                id: "meta.eventName",
            },

            {
                Header: "Customer Phone",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <PIIValidatedText value={(value as GenericWebHookMeta)?.phone ?? "N/A"} />;
                },
                id: "meta.Phone",
            },
            {
                Header: "Customer Name",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return <Text>{(value as GenericWebHookMeta)?.name ?? "Guest"} </Text>;
                },
                id: "meta.Name",
            },

            status,
        ];
    }
    if (integrationType === "woocommerce") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    return <Text>{date}</Text>;
                },
                id: "startedat",
            },
            {
                Header: "Workflow",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text maxW="16ch" isTruncated>
                            {(value as WooCommerceMeta)?.topicTitle}
                        </Text>
                    );
                },
            },
            {
                Header: "OrderId",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    if ((value as ShopifyMeta)?.orderId) {
                        return <Text>{(value as WooCommerceMeta)?.orderId}</Text>;
                    } else {
                        return <Text>N/A</Text>;
                    }
                },
                id: "orderId",
            },
            paymentMethod,
            fullName,
            phone,
            status,
        ];
    }

    if (integrationType === "woocommerce_cartflow") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    return <Text>{date}</Text>;
                },
                id: "startedat",
            },
            {
                Header: "Cart Total",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text maxW="16ch" isTruncated>
                            {(value as WCCartFlowMeta)?.cartTotal}
                        </Text>
                    );
                },
            },
            fullName,
            phone,
            status,
        ];
    }

    if (integrationType === "woocommerce_abandoned_cart") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    return <Text>{date}</Text>;
                },
                id: "startedat",
            },
            {
                Header: "Workflow",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text maxW="16ch" isTruncated>
                            {(value as WCAbandonedCartMeta)?.workflow}
                        </Text>
                    );
                },
            },
            fullName,
            phone,
            status,
        ];
    }

    if (integrationType === "leadsquared") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    const dateLabel = dayjs(value).format("ddd, MMM D, YYYY h:mm A");
                    return <GBTooltip label={dateLabel}>{date}</GBTooltip>;
                },
                id: "createdAt",
            },
            {
                Header: "MessageType",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const type = (value as LeadSquaredMeta)?.messageType ?? "template";
                    return <GBTooltip label={type}>{type}</GBTooltip>;
                },
                id: "MessageType",
            },
            {
                Header: "Phone",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const phone = (value as LeadSquaredMeta)?.phone;
                    const isValidPhoneNumber = phone?.length === 10;
                    return (
                        <Text maxW="16ch" isTruncated>
                            {isValidPhoneNumber ? phone : `+${phone}`}
                        </Text>
                    );
                },
                id: "meta",
            },
            status,
        ];
    }
    if (integrationType === "cleverTap") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    const dateLabel = dayjs(value).format("ddd, MMM D, YYYY h:mm A");
                    return <GBTooltip label={dateLabel}>{date}</GBTooltip>;
                },
                id: "createdAt",
            },
            {
                Header: "MessageType",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const type = (value as CleverTapMeta)?.messageType ?? "template";
                    return <GBTooltip label={type}>{type}</GBTooltip>;
                },
                id: "MessageType",
            },
            {
                Header: "Phone",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const phone = (value as CleverTapMeta)?.phone;
                    const isValidPhoneNumber = phone?.length === 10;
                    return (
                        <Text maxW="16ch" isTruncated>
                            {isValidPhoneNumber ? phone : `+${phone}`}
                        </Text>
                    );
                },
                id: "meta",
            },
            status,
        ];
    }
    if (integrationType === "shopflo") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    const dateLabel = dayjs(value).format("ddd, MMM D, YYYY h:mm A");
                    return <GBTooltip label={dateLabel}>{date}</GBTooltip>;
                },
                id: "createdAt",
            },
            {
                Header: "Workflow",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const workFlowName = integrationType && getWorkFlowName(integrationType, value);
                    return (
                        <GBTooltip label={workFlowName}>
                            <Text maxW="16ch" isTruncated>
                                {workFlowName}
                            </Text>
                        </GBTooltip>
                    );
                },
            },
            phone,
            status,
        ];
    }
    if (integrationType === "calendly") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    const dateLabel = dayjs(value).format("ddd, MMM D, YYYY h:mm A");
                    return <GBTooltip label={dateLabel}>{date}</GBTooltip>;
                },
                id: "startedat",
            },
            {
                Header: "Workflow",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const workFlowName = integrationType && getWorkFlowName(integrationType, value);
                    return (
                        <GBTooltip label={workFlowName}>
                            <Text maxW="16ch" isTruncated>
                                {workFlowName}
                            </Text>
                        </GBTooltip>
                    );
                },
            },
            fullName,
            phone,
            status,
        ];
    }

    if (integrationType === "hubspot") {
        return [
            {
                Header: "Date",
                accessor: "createdAt",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const date: string = value ? formatDate2(value) : "None";
                    const dateLabel = dayjs(value).format("ddd, MMM D, YYYY h:mm A");
                    return <GBTooltip label={dateLabel}>{date}</GBTooltip>;
                },
                id: "startedat",
            },
            {
                Header: "Record ID",
                accessor: "meta",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const recordId = (value as HubspotMeta)?.recordId;
                    return (
                        <GBTooltip label={recordId}>
                            <Text maxW="16ch" isTruncated>
                                {recordId}
                            </Text>
                        </GBTooltip>
                    );
                },
                id: "meta",
            },
            phone,
            status,
        ];
    }

    return [
        {
            Header: "Date",
            accessor: "createdAt",
            // eslint-disable-next-line react/display-name
            Cell: ({ value }) => {
                const date: string = value ? formatDate2(value) : "None";
                const dateLabel = dayjs(value).format("ddd, MMM D, YYYY h:mm A");
                return <GBTooltip label={dateLabel}>{date}</GBTooltip>;
            },
            id: "startedat",
        },
        {
            Header: "Workflow",
            accessor: "meta",
            // eslint-disable-next-line react/display-name
            Cell: ({ value }) => {
                const workFlowName = integrationType && getWorkFlowName(integrationType, value);
                return (
                    <GBTooltip label={workFlowName}>
                        <Text maxW="16ch" isTruncated>
                            {workFlowName}
                        </Text>
                    </GBTooltip>
                );
            },
        },
        {
            Header: "Id",
            accessor: "meta",
            // eslint-disable-next-line react/display-name
            Cell: ({ value }) => {
                if ((value as ShopifyMeta)?.orderId) {
                    return <Text>{(value as ShopifyMeta)?.orderId}</Text>;
                } else if ((value as ZohoBooksMeta)?.id) {
                    return <Text>{(value as ZohoBooksMeta).id}</Text>;
                } else {
                    return <Text>N/A</Text>;
                }
            },
            id: "orderId",
        },
        fullName,
        phone,
        status,
    ];
};

interface LogTableProps {
    accountId?: string;
    integrationId?: string;
    integrationType: IntegrationLog["integrationType"];
}

const LogTable: React.FC<LogTableProps> = ({ accountId, integrationId, integrationType }) => {
    const {
        data: logsData,
        isLoading,
        isError,
        hasNextPage,
        fetchNextPage,
        status,
        isFetchingNextPage,
    } = useGetIntegrationLogs({
        accountId,
        integrationId,
    });

    const TableData = logsData?.pages.flat();

    const target = useRef<HTMLDivElement>(null);
    useIntersectionObserver({
        onIntersect: () => {
            if (!isFetchingNextPage) {
                fetchNextPage();
            }
        },
        target,
        enabled: status === "success" && hasNextPage,
        threshold: 1,
    });

    if (isLoading) {
        return (
            <Grid placeItems="center" bg="white" w="full" h="full" maxH="full" maxW="full">
                <LoadingState title="Loading..." spinnerSize={30} />
            </Grid>
        );
    }

    if (isError || !TableData) {
        return (
            <Grid placeItems="center" color="red.500">
                Something went wrong! Please reload the page or try again after sometime.
            </Grid>
        );
    }
    if (TableData.length < 1) {
        return (
            <Grid placeItems="center" bg="white" w="full" h="full" maxH="full" maxW="full" py={2}>
                <EmptyState
                    title="No Logs"
                    subtitle="Do not have any Logs to view"
                    titleProps={{
                        fontSize: "lg",
                    }}
                    subtitleProps={{
                        fontSize: "sm",
                    }}
                    imageProps={{
                        maxW: "50%",
                    }}
                />
            </Grid>
        );
    }
    const columns = getColumns(integrationType);

    return (
        <Box w="full">
            <Table<IntegrationLog> columns={columns} data={TableData} />
            <VStack>
                <Box h="10px" ref={target} />
                <Button
                    onClick={() => fetchNextPage()}
                    isLoading={isFetchingNextPage}
                    isDisabled={!hasNextPage}
                    colorScheme="blue"
                    size="sm"
                    rounded="md"
                >
                    <span>
                        {isFetchingNextPage ? "Loading More..." : hasNextPage ? "Load More" : "No more to load"}
                    </span>
                </Button>
            </VStack>
        </Box>
    );
};

export default LogTable;
