import { Button, Image } from "@chakra-ui/react";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { mapQueryParams } from "app/utils/fetchUtils";
import { useQueryParam } from "app/utils/history";
import GoogleIcon from "assets/onboarding/google.svg";
import MicrosoftIcon from "assets/onboarding/Microsoft.svg";
import { SERVER_URL_ENV } from "environment";
import React from "react";

export const socialLoginType = ["google", "microsoft"] as const;
export type SocialLoginType = (typeof socialLoginType)[number];

type SocialLoginProps = {
    [key in SocialLoginType]: {
        icon: string;
    };
};

const socialLoginProps: SocialLoginProps = {
    google: {
        icon: GoogleIcon,
    },
    microsoft: {
        icon: MicrosoftIcon,
    },
};

interface SocialSignInBtnProps {
    provider: SocialLoginType;
    page: "login" | "signup";
    dataCy?: string;
    amplitudeEventName?: string;
    skipWorkEmail?: string | null;
    isSkipWorkEmailByCountry?: boolean;
}

const SocialSignInBtn: React.FC<SocialSignInBtnProps> = (props) => {
    const {
        provider = "google",
        page = "login",
        dataCy,
        amplitudeEventName,
        skipWorkEmail,
        isSkipWorkEmailByCountry,
    } = props;

    const params = useQueryParam();
    const referralCode = params.get("referralCode");
    const queryParams = mapQueryParams({
        type: page,
        referralCode,
        skipWorkEmail,
        isSkipWorkEmailByCountry,
        version: "v2",
    });
    return (
        <Button
            variant="unstyled"
            height="fit-content"
            minW="fit-content"
            onClick={() => {
                if (amplitudeEventName) trackEvent({ event: amplitudeEventName });
                window.open(SERVER_URL_ENV + `/auth/${provider}?${queryParams}`, "_self");
            }}
            data-cy={dataCy || `sso-${page}-${provider}`}
        >
            <Image src={socialLoginProps[provider].icon} boxSize="6" />
        </Button>
    );
};

export default SocialSignInBtn;
