/* eslint-disable @typescript-eslint/no-empty-interface */
import {
    AbsoluteCenter,
    Box,
    Button,
    Center,
    Divider,
    Grid,
    Heading,
    HStack,
    Link,
    Stack,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { msg, t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import GBLogoWithBrandName from "app/components/GBLogoWithBrandName";
import SocialSignInBtn from "app/components/SocialSignInBtn";
import useLocalStorage from "app/hooks/useLocalStorage";
import useSocialSigninErrorHandler from "app/hooks/useSocialSigninErrorHandler";
import { authUser, checkIPRestriction, isIPRestricted, loginUser } from "app/modules/authed";
import * as fetchUtils from "app/utils/fetchUtils";
import { InputV2 } from "app/utils/formUtils";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { StaticContext, useHistory } from "react-router";
import { RouteComponentProps, Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import styles from "app/screens/Onboarding/styles";
import { TWO_FA_LOCAL_STORAGE_ACCESSOR } from "app/screens/Onboarding/TwoFA/pages/TwoFANudge";
import OnboardingLayout from "app/screens/Onboarding/Layout/OnboardingLayout";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import ReactHelmet from "react-helmet";
import { urlTracker } from "app/utils/campaign-tracker";
import React from "react";
import GBLoadingCentered from "app/components/GBLoadingCentered";

interface SignInFormValues {
    email: string;
    password: string;
}

const defaultValues = {
    email: "",
    password: "",
};

const schema = () =>
    yup.object().shape({
        email: yup
            .string()
            .trim()
            .required(t`Email is required`)
            .email(t`Provide a valid mail id`),
        password: yup
            .string()
            .trim()
            .required(t`Password is required`),
    });

// eslint-disable-next-line @typescript-eslint/ban-types
interface SignInProps extends RouteComponentProps<{}, StaticContext, { from: { pathname: string; search?: string } }> {}

const SignIn: React.FC<SignInProps> = (props) => {
    const {
        from: { pathname, search },
    } = props.location?.state || { from: { pathname: "/home" } };

    const { _ } = useLingui();
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");

    const [isSubmitting, setSubmitting] = React.useState(false);

    const [, setTwoFALocalState] = useLocalStorage(TWO_FA_LOCAL_STORAGE_ACCESSOR, true, { storeInitialValue: true });

    const toast = useToast();

    useSocialSigninErrorHandler();

    React.useEffect(() => {
        setTwoFALocalState(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { register, errors, handleSubmit } = useForm<SignInFormValues>({
        defaultValues,
        resolver: yupResolver<SignInFormValues>(schema()),
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const checkIsIPRestricted: Parameters<typeof loginUser>[2] = (err) => {
        if (!err) {
            history.push({ pathname, search });
            return;
        } else if (err && isIPRestricted(err)) {
            history.push("/ip-not-allowed", { ipErrMsg: err.message });
            return;
        }
        setSubmitting(false);
    };

    const onLoginSuccess: Parameters<typeof loginUser>[1] = (data) => {
        const { isTwoFARequired, mfaToken } = data;
        if (isTwoFARequired) {
            if (mfaToken) {
                history.push(`/two-factor-auth?c=${mfaToken}`);
                return;
            }

            toast({
                status: "error",
                title: "Login failed",
                description: "We couldn't able to login you, please try again.",
            });
            setSubmitting(false);
        }
        trackEvent({ event: "Login Verified", properties: { state: "Success" } });
    };

    const onLoginFailure: Parameters<typeof loginUser>[2] = (err) => {
        setSubmitting(false);
        checkIsIPRestricted(err);
        trackEvent({ event: "Login Verified", properties: { state: "Failiure" } });
    };
    const onSubmit: SubmitHandler<SignInFormValues> = (values: SignInFormValues) => {
        setSubmitting(true);
        dispatch(loginUser(values, onLoginSuccess, onLoginFailure));
    };

    // This handle social login's
    React.useEffect(() => {
        if (token) {
            dispatch(checkIPRestriction(null));
            fetchUtils.setAuthCookie(token);
            dispatch(authUser(token));
        }
    }, [dispatch, history, token]);

    React.useLayoutEffect(() => {
        urlTracker();
    }, []);

    // show loader on social login
    if (token) {
        return (
            <Grid placeItems="center" w="full" h="100vh">
                <GBLoadingCentered spinnerSize={30} />
            </Grid>
        );
    }

    return (
        <>
            <ReactHelmet>
                <title>Gallabox | Sign Up For Free | Shared Team Inbox | No-Code Chatbot | WhatsApp Broadcast</title>
                <meta
                    name="description"
                    content="Sign up with Gallabox, India's favorite WhatsApp solution provider. Build no-code bots, schedule broadcasts and collaborate with teams. Get started with a 7-day free trial!"
                />
            </ReactHelmet>
            <OnboardingLayout>
                <VStack minH="full" w="full" as="form" onSubmit={handleSubmit(onSubmit)} justifyContent="space-between">
                    <Box w="full">
                        <Center>
                            <GBLogoWithBrandName
                                onClick={() => window.open("https://gallabox.com/")}
                                maxW="110px"
                                maxH="24px"
                            />
                        </Center>
                        <Heading flexDirection="row" textAlign="center" sx={styles.heading} ml={[0, "0 !important"]}>
                            <Trans>Login to your account</Trans>
                        </Heading>

                        <Stack spacing={{ base: 5, sm: 3 }} w="full">
                            <InputV2
                                label={
                                    <Text sx={styles.formlabel}>
                                        {_(msg`Email ID`)} <span style={{ color: "red" }}>*</span>
                                    </Text>
                                }
                                name="email"
                                type="text"
                                size="md"
                                borderRadius="4px"
                                register={register}
                                registerProps={{ required: true }}
                                placeholder={_(msg`Enter your work email`)}
                                error={errors.email?.message}
                                autoFocus
                            />
                            <InputV2
                                label={
                                    <Text sx={styles.formlabel}>
                                        {_(msg`Password`)} <span style={{ color: "red" }}>*</span>
                                    </Text>
                                }
                                name="password"
                                type="password"
                                size="md"
                                borderRadius="4px"
                                register={register}
                                registerProps={{ required: true }}
                                placeholder={_(msg`Enter password`)}
                                error={errors.password?.message}
                                autoComplete="off"
                                showPassword
                            />

                            <Link
                                as={RouterLink}
                                to="/password/reset"
                                fontFamily="inter"
                                sx={{ fontSize: "sm", fontWeight: 500, color: "#0083FF", textAlign: "right" }}
                            >
                                {_(msg`Forgot Password?`)}
                            </Link>
                        </Stack>
                    </Box>
                    <Stack spacing={2} w="full">
                        <Button
                            type="submit"
                            mt={2}
                            colorScheme="brand"
                            sx={{ ...styles.button }}
                            width="full"
                            isLoading={isSubmitting}
                        >
                            <span>{_(msg`Login`)}</span>
                        </Button>
                        <Text textAlign="center" sx={styles.subheading} fontSize="2xs" ml={[0, "0 !important"]} my={2}>
                            <Trans>
                                {"Don't have an account? "}
                                <Link
                                    as={RouterLink}
                                    to="/signup"
                                    color="#0083FF"
                                    onClick={() => {
                                        trackEvent({ event: "ACCOUNT Sign Up Clicked" });
                                    }}
                                >
                                    Sign up
                                </Link>
                            </Trans>
                        </Text>
                        <Box position="relative" my={3} w="100%">
                            <Divider color="gray.300" />
                            <AbsoluteCenter bg="white" px={{ base: 2, sm: 4 }}>
                                <Text fontSize="xs" textAlign="center" color="gray.700">
                                    {_(msg`or login with`)}
                                </Text>
                            </AbsoluteCenter>
                        </Box>

                        <HStack w="full" justifyContent="center" spacing={3}>
                            <SocialSignInBtn
                                page="login"
                                provider="google"
                                amplitudeEventName="Login SSO Microsoft Clicked"
                            />
                            <SocialSignInBtn
                                page="login"
                                provider="microsoft"
                                amplitudeEventName="Login SSO Google Clicked"
                            />
                        </HStack>
                    </Stack>
                </VStack>
            </OnboardingLayout>
        </>
    );
};

export default SignIn;
